import styled from 'styled-components';

export const Body = styled.div`
  width: 840px;
  margin: 0 auto;
  padding: 80px 0;
  padding-bottom: 0;
  text-align: center;
  @media (max-width: 870px) {
    width: 100%;
    padding: 35px;
    padding-bottom: 0;
  }
`;

export const Header = styled.h1`
  margin: 0 0 35px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 50px;
  line-height: 55px;
  text-align: center;
  color: #00539d;
  @media (max-width: 870px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const Header2 = styled.h2`
  display: inline-block;
  padding: 20px 30px;
  margin: 0 0 35px 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  font-weight: bold !important;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #00539d;
  background: #ffc905;
  border-radius: 10px;
  @media (max-width: 870px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Paragraph = styled.p`
  margin: 0 0 35px 0;
  padding: 0;
`;
